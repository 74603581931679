import Pusher, { Channel } from "pusher-js";

class PusherService {
  private pusherInstances: { [cluster: string]: Pusher } = {};

  constructor() {
    const config: { appKey: string; cluster: string }[] = [];

    if (
      process.env.REACT_APP_PUSHER_API_KEY &&
      process.env.REACT_APP_PUSHER_CLUSTER
    ) {
      config.push({
        appKey: process.env.REACT_APP_PUSHER_API_KEY,
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      });
    }

    if (
      process.env.REACT_APP_PUSHER_API_KEY_SECONDARY &&
      process.env.REACT_APP_PUSHER_CLUSTER_SECONDARY
    ) {
      config.push({
        appKey: process.env.REACT_APP_PUSHER_API_KEY_SECONDARY,
        cluster: process.env.REACT_APP_PUSHER_CLUSTER_SECONDARY,
      });
    }

    if (config.length === 0) {
      console.error("Missing Pusher configuration");
    }

    config.forEach(({ cluster, appKey }) => {
      const pusher = new Pusher(appKey, { cluster });
      this.pusherInstances[cluster] = pusher;
    });
  }

  subscribe(channel: string): Channel[] {
    return Object.values(this.pusherInstances).map((pusher) => {
      return pusher.subscribe(channel);
    });
  }

  unsubscribe(channel: string): void {
    Object.values(this.pusherInstances).forEach((pusher) => {
      pusher.unsubscribe(channel);
    });
  }

  disconnect(): void {
    Object.values(this.pusherInstances).forEach((pusher) => {
      pusher.disconnect();
    });
  }

  reconnect(): void {
    Object.values(this.pusherInstances).forEach((pusher) => {
      pusher.connect();
    });
  }
}

export const pusher = new PusherService();
