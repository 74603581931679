import { Button } from "componentsV2/Button";
import { Modal } from "componentsV2/Modal";
import React, { useEffect, useState } from "react";
import { pusher } from "services/Pusher";
import { useIdleTimer } from "react-idle-timer";

const timeout = 3600000;
const promptBeforeIdle = 10000;

export const IdleModal: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  const onIdle = () => {
    setOpen(true);
    pusher.disconnect();
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { activate, isPrompted } = useIdleTimer({
    onIdle,
    onPrompt,
    timeout,
    promptBeforeIdle,
    eventsThrottle: 500,
    crossTab: true,
    syncTimers: 500,
  });

  const handleStillHere = () => {
    activate();
    setOpen(false);
  };

  useEffect(() => {
    if (!isPrompted()) {
      pusher.reconnect();
    }
  }, [open]);

  return (
    <div>
      <Modal
        title="Are you still here?"
        active={open}
        handleClose={() => setOpen(false)}
      >
        <div className="flex flex-col items-center">
          <Button type="button" onClick={handleStillHere}>
            Yes
          </Button>
        </div>
      </Modal>
    </div>
  );
};
