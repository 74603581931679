import client from "./client";
import { User, Facility, TaskAssignment, Paginated, CallQueue } from "./types";

export const getAgent = () => client.get<User>("v1/users/current");

export const getAgentFacilities = () =>
  client.get<Facility[]>("v1/users/current/facilities");

export const updateAgent = (data: Partial<User>) =>
  client.patch<User>("v1/users/current", data);

export const updateAgentFacilities = (data: Pick<User, "facilityUrls">) =>
  client.patch<User>("v1/users/current/facilities", data);

export const getAllAgents = () => client.get<User[]>("v1/users/");

export const getOrganizationAgents = (
  limit = 200,
  offset = 0,
  isActive = true
) =>
  client.get<Paginated<User>>("v1/organizations/current/users", {
    params: { limit: limit, offset: offset, is_active: isActive },
  });

export const getTaskAssignments = () =>
  client.get<Paginated<TaskAssignment>>(
    "v1/users/current/assignments?status__in=ACCEPTED,CREATED,DELIVERED"
  );

export const getCallQueue = () =>
  client.get<CallQueue>("v1/organizations/current/realtime-tasks-queue");
